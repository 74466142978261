import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

export type CloseProps = {
  width?: string;
  height?: string;
};

const Close: FC<CloseProps & IconProps> = ({ theme, color = theme.colors.white, width = '100%', height = '100%' }) => (
  <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.73293 4.00657L7.84797 0.891434C8.0507 0.688808 8.0507 0.361189 7.84797 0.158562C7.64535 -0.0440649 7.31773 -0.0440649 7.1151 0.158562L3.99996 3.2737L0.884915 0.158562C0.682193 -0.0440649 0.354668 -0.0440649 0.152041 0.158562C-0.0506805 0.361189 -0.0506805 0.688808 0.152041 0.891434L3.26709 4.00657L0.152041 7.12171C-0.0506805 7.32433 -0.0506805 7.65195 0.152041 7.85458C0.253023 7.95566 0.385798 8.00643 0.518478 8.00643C0.651159 8.00643 0.783839 7.95566 0.884915 7.85458L3.99996 4.73944L7.1151 7.85458C7.21618 7.95566 7.34886 8.00643 7.48154 8.00643C7.61422 8.00643 7.7469 7.95566 7.84797 7.85458C8.0507 7.65195 8.0507 7.32433 7.84797 7.12171L4.73293 4.00657Z"
      fill={color}
    />
  </svg>
);
export default withTheme(Close);
