import React, { FC, useEffect, useState } from 'react';

export const DeferRender: FC = ({ children }) => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const handle = requestAnimationFrame(() => {
      setRendered(true);
    });

    return () => cancelAnimationFrame(handle);
  }, []);

  return rendered ? <>{children}</> : null;
};
