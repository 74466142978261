import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';
import { MainSliderProps } from './MainSlider';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  margin: 0 auto;
  position: relative;

  ${mq.large`
      flex-direction: row;
  `}
`;

export const StyledTitleBox = styled.div<{
  boardTheme: MainSliderProps['boardTheme'];
}>`
  padding: ${({ theme }) => theme.spacing(9, 0)};
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};

  ${mq.large`
    background: none;

    ${({ boardTheme }) =>
      boardTheme === 'rectangle' &&
      css`
        padding-right: ${({ theme }) => theme.spacing(4)};
      `}

    &:before {
      background: transparent;
    }
  `}
`;

export const StyledLocations = styled.div`
  margin: ${({ theme }) => theme.spacing(-2, 0, 3)};
`;

export const StyledTitleWrapper = styled.div<{
  variant: MainSliderProps['boardSize'];
}>`
  z-index: 3;
  display: flex;
  flex-direction: column;

  ${mq.large`
    width: ${({ variant }) => (variant === 'xl' ? '330px' : '312px')};
  `}
`;

export const StyledSliderBox = styled.div<{ variant: MainSliderProps['boardTheme'] }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ variant }) =>
    variant === 'cropped' &&
    css`
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
    `}

  ${mq.large`
    width: 100%;
  `}
`;

export const StyledVideoButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(12)};

  ${mq.large(
    css`
      display: none;
    `
  )}
`;
export const StyledDescriptionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0, 5)};

  ${mq.large`
    margin: ${({ theme }) => theme.spacing(2, 0, 4)} ;
  `}
`;

export const StyledMotionWrapper = styled(motion.div)`
  z-index: 10;

  ${mq.large`
    display: none;
  `}
`;
