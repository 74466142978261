import React, { FC, useEffect } from 'react';

import { scrollController } from 'helpers/scrollController';

import Close from 'components/atoms/icons/Close/Close';
import VideoPlayer from 'components/atoms/VideoPlayer/VideoPlayer';

import { StyledCloseButton, StyledContentWrapper, StyledWrapper } from './PopupVideo.styles';

export type PopupVideoProps = {
  src: string;
  onClosePopup: () => void;
};

const PopupVideo: FC<PopupVideoProps> = ({ src, onClosePopup }) => {
  const closePopup = () => {
    scrollController.enableScroll();
    onClosePopup();
  };

  useEffect(() => {
    scrollController.disableScroll();

    return () => {
      scrollController.enableScroll();
    };
  }, []);

  return (
    <StyledWrapper onClick={closePopup}>
      <StyledContentWrapper>
        <StyledCloseButton onClick={closePopup}>
          <Close width="16px" height="16px" />
        </StyledCloseButton>
        <VideoPlayer src={src} controls hasHiddenOverflow autoPlay />
      </StyledContentWrapper>
    </StyledWrapper>
  );
};
export default PopupVideo;
