import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ theme }) => rgba(theme.colors.blackRock, 0.9)};
  z-index: 4;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
`;

export const StyledCloseButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  padding: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: calc((var(--vh, 1vh) * 177) - 64px);
  height: calc(56.25vw + 64px);
  max-height: calc((var(--vh, 1vh) * 100));
  margin: 0 auto;

  @media (orientation: portrait) {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;
