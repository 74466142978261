class ScrollController {
  isScrollable = true;

  disableScroll = (): void => {
    if (this.isScrollable) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.position = 'fixed';
      this.isScrollable = false;
    }
  };

  enableScroll = (): void => {
    if (!this.isScrollable) {
      const scrollY = document.body.style.top;
      document.body.style.width = null;
      document.body.style.position = null;
      document.body.style.top = null;
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      this.isScrollable = true;
    }
  };
}

export const scrollController = new ScrollController();
