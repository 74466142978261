import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';

import { SlideProps } from '../MainSlider';
import { StaticSlider } from '../StaticSlider/StaticSlider';
import { StyledSwiper } from './DynamicSlider.styles';

export type DynamicSliderProps = {
  slides: Array<SlideProps>;
};

export const DynamicSlider: FC<DynamicSliderProps> = ({ slides }) =>
  slides.length === 1 ? (
    <StaticSlider {...slides[0]} />
  ) : (
    <StyledSwiper updateOnWindowResize threshold={10}>
      {slides.map((slide) => (
        <SwiperSlide zoom key={slide.id} style={{ height: '100%' }}>
          <StaticSlider {...slide} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
