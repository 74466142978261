import React, { FC, ReactNode, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { scrollToSection } from 'utils/scroll';
import Button from 'utils/types/Button';
import UploadedImg from 'utils/types/UploadedImg';
import useMedia from 'hooks/useMedia';

import ContentBox, { ColumnColors } from 'components/ContentBox/ContentBox';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import { GenericProps } from 'components/atoms/Button/GenericButton';
import Typography from 'components/atoms/Typography/Typography';
import PopupVideo from 'components/molecules/PopupVideo/PopupVideo';
import { DeferRender } from 'components/DeferRender/DeferRender';
import { Marked } from 'components/Marked/Marked';

import { DynamicSlider } from './DynamicSlider/DynamicSlider';

import {
  StyledTitleBox,
  StyledSliderBox,
  StyledTitleWrapper,
  StyledVideoButtonWrapper,
  StyledDescriptionWrapper,
  StyledMotionWrapper
} from './MainSlider.styles';

export type SlideProps = {
  id: number;
  type: string;
  image?: UploadedImg;
  videoUrl?: string;
};

export type MainSliderProps = {
  title: MDXRemoteSerializeResult;
  description: MDXRemoteSerializeResult;
  button: {
    textOnButton: string;
    href: string;
    variant: GenericProps['variant'];
  };
  mobileBackground: UploadedImg;
  singleSlides: Array<SlideProps>;
  videoButton?: Button | null;
  boardTheme?: 'rectangle' | 'cropped';
  boardColor?: 'dark' | 'purple';
  boardSize?: 'sm' | 'xl';
  customContent?: ReactNode;
};

// @deprecated: Please use components/ContentBox instead
export const MainSlider: FC<MainSliderProps> = ({
  title,
  description,
  button,
  singleSlides,
  videoButton,
  boardTheme = 'rectangle',
  boardColor = 'dark',
  boardSize = 'xl',
  mobileBackground,
  customContent = null
}) => {
  const buttonVariant = button.variant?.replace('_', '-') as GenericProps['variant'];
  const color = boardColor as ColumnColors;
  const isXl = boardSize === 'xl';
  const video = singleSlides.find((slide) => slide.type === 'video');
  const [showVideo, setShowVideo] = useState(false);
  const { isMobile } = useMedia();

  return (
    <ContentBox
      mobileBackground={mobileBackground || (singleSlides.length > 0 && singleSlides[0].image)}
      crop={boardTheme === 'cropped' ? 'rightToLeft' : undefined}
      leftColumnColor={color}
      rightColumnColor={color}
      leftColumnContent={
        <StyledTitleBox boardTheme={boardTheme}>
          <StyledTitleWrapper variant={boardSize}>
            <Typography color="inherit" variant="body2" weight="300">
              <Marked>{description}</Marked>
            </Typography>
            <StyledDescriptionWrapper>
              <Typography variant={isXl ? 'h2' : 'h3'} renderAs="h1" weight="200">
                <Marked>{title}</Marked>
              </Typography>
            </StyledDescriptionWrapper>
            {customContent}
            {button?.href.startsWith('#') ? (
              <FuncButton variant={buttonVariant} onClick={() => scrollToSection(button.href.substring(1))}>
                {button.textOnButton}
              </FuncButton>
            ) : (
              <LinkedButton variant={buttonVariant} href={button.href}>
                {button.textOnButton}
              </LinkedButton>
            )}
            {videoButton && (
              <StyledVideoButtonWrapper>
                <FuncButton width="208px" height="56px" variant="tertiary-white" onClick={() => setShowVideo(true)}>
                  {videoButton.buttonCopy}
                </FuncButton>
              </StyledVideoButtonWrapper>
            )}
          </StyledTitleWrapper>
          <AnimatePresence>
            {videoButton && showVideo && (
              <StyledMotionWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <PopupVideo src={video.videoUrl} onClosePopup={() => setShowVideo(false)} />
              </StyledMotionWrapper>
            )}
          </AnimatePresence>
        </StyledTitleBox>
      }
      rightColumnContent={
        !isMobile && (
          <DeferRender>
            <StyledSliderBox variant={boardTheme}>
              <DynamicSlider slides={singleSlides} />
            </StyledSliderBox>
          </DeferRender>
        )
      }
    />
  );
};
